<template>
  <div class="commonCode">
    <div class="commonCode-t"><img src="./img/banner.png" alt="" /></div>
    <div class="commonCode-b">
      <div class="commonCode-b-body">
        <div class="title">
          <div class="title-l">仅限本人使用</div>
          <div class="title-r" @click="refresh">
            <img src="./img/refresh.png" alt="" />
          </div>
        </div>
        <div class="code" v-if="postData.qrCode">
          <vue-qr :text="postData.qrCode" :size="size"></vue-qr>
        </div>
        <div class="info">
          <div class="info-l"><img src="./img/info.png" alt="" /></div>
          <div class="info-r">如扫码失败，请调亮手机屏幕亮度</div>
        </div>
        <div class="line"></div>
        <div class="item1" v-for="(item, index) in list" :key="index">
          <div class="title">{{ item.applyPlace }}开门权限</div>
          <div
            v-show="item.auditStatus == null || item.auditStatus == 2"
            class="btn"
            @click="toPath('applyForQRcode')"
          >
            立即申请
          </div>
          <div
            v-show="item.auditStatus == 0"
            class="btn"
            style="background: #ffb552"
          >
            审核中
          </div>
          <div class="success" v-show="item.auditStatus == 1">
            <div class="icon_box"><img src="./img/success.png" alt="" /></div>
            <div class="text">已授权</div>
          </div>
        </div>
      </div>
    </div>
    <div class="applicationHistory" @click="toPath('applicationHistory')">
      申请历史
    </div>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import { toRegister } from "@/utils/common.js";
import { getCodeUrl, getAppRecordUrl } from "./api.js";
import wx from "weixin-js-sdk";
var moment = require("moment");
export default {
  name: "commonCode",
  data() {
    return {
      size: "",
      postData: {},
      ws: "",
      list: [],
    };
  },
  created() {
    this.size = (document.documentElement.clientWidth / 375) * 211;
    window.addEventListener("beforeunload", (e) => this.closeWebsocket(e));
  },
  components: {
    vueQr,
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) => this.closeWebsocket(e));
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    assetId() {
      return this.$store.state.assetId;
    },
  },
  mounted() {
    this.createWebsocket();
    // this.getJurisdictionDetail();
  },
  methods: {
    getJurisdictionDetail() {
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(`${getAppRecordUrl}`, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.list = res.data;
          }
        });
    },
    toPath(path) {
      this.$router.push({
        name: path,
      });
    },
    closeWebsocket() {
      if (this.ws) {
        this.ws.close();
        this.ws.onclose = function (evt) {
          console.log("Connection closed.");
        };
      }
    },
    refresh() {
      this.createWebsocket();
    },
    async getCode(sessionId) {
      let params = {
        userId: this.userId,
        sessionId: sessionId,
      };
      let res = await this.$axios.post(
        `${getCodeUrl}`,
        this.$qs.stringify(params),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (res.code === 200) {
        this.postData = res.data;
      }
    },
    createWebsocket() {
      let _this = this;
      this.ws = new WebSocket(
        "wss://future.zhuneng.cn/pubApi/external/ws/hfCode"
      );

      this.ws.onopen = function (evt) {
        console.log("Connection open ...");
      };

      this.ws.onmessage = function (evt) {
        console.log("Received Message: " + evt.data);
        if (evt.data && JSON.parse(evt.data)) {
          let obj = JSON.parse(evt.data);
          if (obj.sessionId) {
            _this.getCode(obj.sessionId);
          }
        }
      };
    },
  },
};
</script>

<style lang="less" scoped>
.commonCode {
  min-height: 100vh;
  padding-bottom: 100px;
  background: rgba(0, 0, 0, 0.04);

  .commonCode-t {
    height: 320px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .applicationHistory {
    width: 194px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    background: #ffffff;
    margin: 66px auto 0;
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1a1c34;
  }

  .commonCode-b-body {
    z-index: 99;
    position: relative;
    width: 690px;
    // height: 998px;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(144, 144, 144, 0.15);
    border-radius: 16px;
    padding: 38px 38px 30px;
    box-sizing: border-box;
    margin: -100px auto 0;

    .title {
      display: flex;
      justify-content: space-between;
      align-content: center;

      .title-l {
        font-size: 38px;
        font-weight: 600;
        color: #1a1c34;
        line-height: 52px;
      }

      .title-r {
        width: 52px;
        height: 52px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .item1 {
      width: 100%;
      height: 64px;
      line-height: 64px;
      position: relative;
      box-sizing: border-box;
      margin-bottom: 30px;

      .btn {
        width: 174px;
        height: 100%;
        background: #007eff;
        border-radius: 32px;
        text-align: center;
        line-height: 64px;
        font-size: 28px;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        top: 0;
        right: -2px;
      }

      .title {
        font-size: 32px;
        font-weight: 500;
        color: #1a1c34;
        margin-left: 18px;
      }

      .success {
        position: absolute;
        top: 0;
        right: 28px;
        display: flex;
        align-content: center;

        .icon_box {
          img {
            width: 22px;
            height: 22px;
          }
        }

        .text {
          margin-left: 8px;
          font-size: 28px;
          color: #007eff;
        }
      }
    }

    .line {
      width: 588px;
      height: 2px;
      background-color: rgba(0, 0, 0, 0.08);
      margin: 64px auto 40px;
    }

    .code {
      width: 404px;
      height: 404px;
      margin: 92px auto 80px;
    }

    .info {
      display: flex;
      padding-left: 84px;
      align-content: center;

      .info-l {
        width: 40px;
        height: 40px;
        margin-right: 14px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .info-r {
        font-size: 28px;
        font-weight: 400;
        color: #a8a8a8;
        line-height: 40px;
      }
    }
  }
}
</style>
