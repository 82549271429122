let origin = "https://future.zhuneng.cn";
//获取二维码
const getCodeUrl = `/pubApi/external/hfCode/getUserQrCode`;
//申请开门
const applyDoorUrl = `/pubApi/external/hfCode/applyDoor`;
//查询历史申请记录
const getApplyHistoryUrl = `/pubApi/external/hfCode/getApplyHistory`;
//页面展示申请记录
const getAppRecordUrl = `/pubApi/external/hfCode/getAppRecord`;
//个人信息
const userInfoUrl = `/miniApi/user/mini/user-info`;
export { 
    getCodeUrl,
    applyDoorUrl,
    getApplyHistoryUrl,
    getAppRecordUrl,
    userInfoUrl
 };
